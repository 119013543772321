import axios from 'axios';
import Toaster from '../components/Toaster/Toaster';

export const BASE_URL = 'https://srh-api.arambh.tech/api'
// export const BASE_URL = 'http://localhost:9003/api'

export const getAuthInfo = async () => {
    try {
        return JSON.parse(localStorage.getItem('userInfo'));
    } catch (error) {
        throw error;
    }
}

export const getProfileInfo = async () => {
    try {
        let authInfo = await getAuthInfo();
        if(!authInfo) return;
        const result = await axios.get(`${BASE_URL}/user/profile`,{
            headers:{
                Authorization:authInfo?.token
            }
        });
        let data = result.data;
        if(data) localStorage.setItem('userInfo', JSON.stringify({...data, token: authInfo?.token}));
        return data;
    } catch (error) {
        throw error;
    }
}

export const loginUser = async (username, password) => {
    try {
        let result = await axios.post(`${BASE_URL}/user/login`, {
            username:username,
            password:password
        });
        localStorage.setItem('userInfo', JSON.stringify(result?.data?.data));
        return result?.data ?? {};
    } catch (error) {
        const {response:{data:{errorMessage}}} = error;
        Toaster('error', errorMessage ?? "Error Notification !");
        throw error;
    }
}

export const registerUser = async (body) => {
    try {
        let result = await axios.post(`${BASE_URL}/user/register`, {
            name:body?.name,
            password:body?.password,
            email: body?.email,
            phone: body?.phone
        });

        localStorage.setItem('userInfo', JSON.stringify(result?.data?.data));
        return result?.data ?? {};
    } catch (error) {
        throw error;
    }
}