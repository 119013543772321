
import React, { useState, useEffect } from 'react';
import "./../../css/responsive.css";
import '../header/header.css';
import { getCategoryWithChildren } from './../../services/categoryService';
import logo from './../../images/srh-logo.png';
import { getAuthInfo } from '../../services/authService';

const Header = () => {

const [categories, setCategories] = useState([]);
const [authInfo, setAuthInfo] = useState({});
const [cartCount, setCartCount] = useState(0);
const [productSearch, setProductSearch] = useState(null);
const [wishlistCount, setWishlistCount] = useState(0);

    const [openDropdown, setOpenDropdown] = useState(null);
    const handleMouseEnter = (index) => setOpenDropdown(index);
    const handleMouseLeave = () => setOpenDropdown(null);

  const checkLoggedInInfo = async () => {
    try {
        const result = await getAuthInfo()
        if(result?.token) setAuthInfo(result);
        if(result?.data?.wishlistCount)  setWishlistCount(result?.data?.wishlistCount);
        if(result?.data?.cartCount)  setCartCount(result?.data?.cartCount);
        } catch (error) {
            console.error('Error fetching authInfo:', error);
        }
    };

  const loadCategories = async () => {
    try {
        const {data} = await getCategoryWithChildren()
        setCategories(data);
    } catch (error) {
        console.error('Error fetching categories:', error);
    }
    };
  const handleLogout = async () => {
    try {
        localStorage.clear();
        setAuthInfo({});
    } catch (error) {
    console.error('Error fetching banners:', error);
    }
};
  const handleSearch = async (e) => {
    try {
        setProductSearch(e.target.value)
    } catch (error) {
    console.error('Error fetching banners:', error);
    }
};

  useEffect(() => {
    loadCategories();
    checkLoggedInInfo();
  }, []);

    return (
        <header className="header shop">
            <div className="topbar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">

                            <div className="top-left">
                                <ul className="list-main">
                                    {/* <li>Upload  <i className="fa fa-camera"></i></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="right-content">
                                <ul className="list-main">
                                    {/* <li> <a href="#">Sell With Us</a></li> */}
                                    {/* <li> <a href="#">Offer For You</a></li> */}
                                    <li> <a href="/contact">Connect</a></li>
                                    <li> <a href="/about-us">About</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="middle-inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-12">
                            {/* <!-- Logo --> */}
                            <div className="logo">
                                <a href=""><img src={logo} alt="logo" /></a>
                            </div>
                            {/* <!--/ End Logo --> */}
                            {/* <!-- Search Form --> */}
                            <div className="search-top">
                                <div className="top-search"><a href="#0"><i className="search"></i></a></div>
                                {/* <!-- Search Form --> */}
                                <div className="search-top">
                                    <form className="search-form">
                                        <input type="text" placeholder="Search here..." name="search" onClick={handleSearch}/>
                                        <a href={`/product-grids?title=${productSearch}`}>
                                        <button value="search" type="button"><i className="search"></i></button>
                                        </a>
                                    </form>
                                </div>
                                {/* <!--/ End Search Form --> */}
                            </div>
                            {/* <!--/ End Search Form --> */}
                            <div className="mobile-nav"></div>
                        </div>
                        <div className="col-lg-8 col-md-7 col-12">
                            <div className="search-bar-top">
                                <div className="search-bar" style={{ width: '100%' }}>
                                        <input name="search" placeholder="Search Products Here....." type="search" onClick={handleSearch} />
                                        <a href={`/product-grids?title=${productSearch}`}>
                                            <button className='btnn' type="button"><i className="fas fa-search"></i></button>
                                        </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-12" style={{marginTop:'6px'}}>
                            <div className="right-bar">
                                {/* <!-- Search Form --> */}
                                <div className="sinlge-bar shopping">
                                    {
                                        !authInfo?.token && (
                                            <><a href="/login" className="single-icon"><span className="total-count1" style={{ fontSize: '15px' }}>Login</span></a><a href="/register" className="single-icon"><span className="total-count1" style={{ fontSize: '15px' }}>Account</span></a></>
                                        )
                                    }
                                    {
                                        authInfo?.token && (
                                            <><a href="/profile" className="single-icon"><span className="total-count1" style={{ fontSize: '15px' }}>{authInfo?.data?.name ?? authInfo?.name}</span></a><a href="#" className="single-icon"><span className="total-count1" style={{ fontSize: '15px' }} onClick={handleLogout}>Logout</span></a></>
                                        )
                                    }

                                    <a href="/wishlist" className="single-icon"><i className="fa fa-heart-o"></i> <span className="total-count">{wishlistCount}</span></a>
                                </div>

                                <div className="sinlge-bar shopping">
                                    <a href="/cart" className="single-icon"><i className="fa fa-shopping-bag"></i> <span className="total-count">{cartCount}</span></a>
                                </div>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNavDarkDropdown"
                                    aria-controls="navbarNavDarkDropdown"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"><i className="fa fa-list-ul" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-inner">
                <div className="container">
                    <div className="cat-nav-head">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="menu-area">
                                    {/* <!-- Main Menu --> */}
                                    <nav className="navbar navbar-expand-lg">

                                        <div className="collapse  navbar-collapse" id="navbarNavDarkDropdown">
                                            <div className="nav-inner w-100">
                                                <ul className="nav main-menu menu navbar-nav">
                                                    <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
                                                        <ul className="navbar-nav w-100" style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                            <li className=""><a href="/">Home</a></li>
                                                            {categories.map((item, index) => (
                                                                <li className="nav-item dropdown"
                                                                    onMouseEnter={() => handleMouseEnter(index)}
                                                                    onMouseLeave={handleMouseLeave}>
                                                                    <a
                                                                        className="nav-link dropdown-toggle"
                                                                        href="#"
                                                                        id={`navbarDarkDropdownMenuLink${index}`}
                                                                        role="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded={openDropdown === index}
                                                                    >
                                                                        {item.title}
                                                                    </a>
                                                                    <ul
                                                                        className={`dropdown-menu dropdown-menu-dark ${openDropdown === index ? 'show' : ''}`}
                                                                        aria-labelledby={`navbarDarkDropdownMenuLink${index}`}
                                                                        style={{ maxHeight: '300px', overflowY: 'auto' }}
                                                                    >
                                                                        {item['children'].map((childItem, childIndex) => (
                                                                            <li key={childItem?._id ?? childIndex}>
                                                                                <a className="dropdown-item" href={`/product-grids?parent=${item?.slug}&category=${childItem?.slug}`} style={{ fontSize: '12px', padding: '0px 10px' }}>
                                                                                    {childItem?.title ?? childItem}
                                                                                </a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                            ))}
                                                            <li className=""><a href="/product-grids">More</a></li>
                                                        </ul>
                                                    </div>

                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
