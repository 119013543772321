import { Modal } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useEffect } from 'react';
import { createCart } from '../../services/cartService';
import { updateWishlist } from '../../services/wishlistService';
import { useNavigate } from 'react-router-dom';
import ImageComponent from '../Image/Image';

const ProductModal = ({ showModal, handleCloseModal, modalContent }) => {
  const navigate = useNavigate();
  const handleWishlist = async (type) => {
    try {
    const result = await updateWishlist({productId: modalContent.slug, type: type})
    if(result) navigate("/login")
    } catch (error) {
    console.error('Error fetching banners:', error);
    }
};

  const addToCart = async () => {
   const result = await createCart(1, modalContent.slug);
   if(!result) navigate('/login');
  };

  useEffect(() => {
    // Log the props when they change
  }, [showModal, handleCloseModal, modalContent ]);
  if (!modalContent) return null;
  
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <div className="modal-dialog" role="document" style={{ margin: "0px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={handleCloseModal} data-dismiss="modal" aria-label="Close">
              <span className="fas fa-times" aria-hidden="true"></span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row no-gutters" style={{ marginLeft: "0", marginRight: "0" }}>
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="product-gallery">
                  <div className="quickview-slider-active">
                    <div className="single-slider">
                      <ImageComponent src={modalContent?.image} alt={modalContent?.title} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="quickview-content">
                  <h2>{modalContent.name}</h2>
                  <div className="quickview-ratting-review">
                    <div className="quickview-ratting-wrap">
                      <div className="quickview-ratting">
                        {[...Array(5)].map((star, i) => (
                          <i key={i} className="fa fa-star"></i>
                        ))}
                      </div>
                      <a href="#"> ({modalContent.totalReview} customer review)</a>
                    </div>
                    <div className="quickview-stock">
                      <span><i className="fa fa-check-circle-o"></i> 122 in stock</span>
                    </div>
                  </div>
                  <h3><small><del className="text-muted">{modalContent.oldPrice}</del></small>{modalContent.price} </h3>
                  <div className="quickview-peragraph">
                    <p>
                      <span style={{ color: 'rgb(15, 17, 17)', fontFamily: 'Amazon Ember, Arial, sans-serif', fontSize: '14px' }}>{modalContent.description}
                      </span>
                    </p>
                  </div>
                        <form action="/add-to-cart" method="POST" class="mt-4">
                          <input type="hidden" name="_token" value="Sin5WiVKfgn3E386VRkz7p5IONQ33i62W00avwEm" />
                          <div class="quantity">
                            {/* <!-- Input Order --> */}
                            <div class="input-group">
                              <div class="button minus">
                                <button type="button" class="btn btn-primary btn-number"
                                  disabled="disabled" data-type="minus" data-field="quant[1]">
                                  <i class="ti-minus"></i>
                                </button>
                              </div>
                              <input type="hidden" name="slug" value="ring-exclusive" />
                              <input type="text" name="quant[1]" class="input-number" data-min="1"
                                data-max="1000" value="1" />
                              <div class="button plus">
                                <button type="button" class="btn btn-primary btn-number"
                                  data-type="plus" data-field="quant[1]">
                                  <i class="ti-plus"></i>
                                </button>
                              </div>
                            </div>
                            {/* <!--/ End Input Order --> */}
                          </div>
                          <div class="add-to-cart">
                            <button type="button" class="btn" onClick={addToCart}>Add to cart</button>
                            <a className="btn min" onClick={() => handleWishlist(modalContent?.isWishlist ?  'remove' : 'add')}>
                                                            { modalContent?.isWishlist && (<i className="fa fa-heart-o" style={{color:'red'}}></i>)}
                                                            { !modalContent?.isWishlist && (<i className="fa fa-heart-o"></i>)}
                                                            </a>
                          </div>
                        </form>
                        <div class="default-social">
                        </div>
                  {/* Additional content like size, add to cart, etc. */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;
