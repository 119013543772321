import React, { useState } from 'react';

const ImageComponent = ({ src, alt, className }) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageError = () => {
    setImageSrc('https://placehold.co/300');
  };

  return (
    <img

    crossorigin='anonymous' 
      src={imageSrc ?? ""}
      className={className ?? ""}
      alt={alt ?? ""}
      onError={handleImageError}
    //   style={{ width: '100%', height: 'auto' }}
    />
  );
};

export default ImageComponent;
