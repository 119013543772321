import React, { useEffect, useState } from "react";
import "../../css/responsive.css";
import "../../css/bootstrap.css";
import "../../css/style.css";
import { getCartInfo } from "../../services/cartService";
import { getAuthInfo } from "../../services/authService";
import { placeOrder } from "../../services/orderService";

const Checkout = () => {
	const [orderDetail, setOrderDetail] = useState({});
        const [cart, setCart] = useState([]);
        const[userInfo, setUserInfo] = useState({})

        const createOrder = async () => {
			let createObj = {
				address:{
					name: orderDetail?.name ?? userInfo?.name,
					email: orderDetail?.email ?? userInfo?.email,
					phone: orderDetail?.phone ?? userInfo?.phone,
					pincode: orderDetail?.pincode,
					address: orderDetail?.address
				  },
				products: cart?.products.map(item => ({
					productId: item?.productId,
					title: item?.product?.title,
					image: item?.product?.image,
					description: item?.product?.description,
					price: item?.product?.price,
					discountPrice: item?.product?.discountPrice,
					qty: item?.qty ?? 1
				})),
				totalQty: cart?.totalQty ?? 1,
				totalPrice: cart?.totalAmount ?? 0,
				paidPrice: 0,
			}
            console.log("Form Data:", createObj);
			await placeOrder(createObj);
        };
        const getLoginInfo = async () => {
            try {
                const result = await getAuthInfo();
                if(result?.token) {
					setUserInfo(result);
					setOrderDetail({...orderDetail, name: result?.data?.name, email: result?.data?.email, phone: result?.data?.phone})
				}
                } catch (error) {
                    console.error('Error fetching authInfo:', error);
                }
        };
        const handleChange = async (e, field) => {
            setOrderDetail({...orderDetail, [field]: e.target.value})
        };

        const fetchCartWithPagination = async () => {
            try {
                const data = await getCartInfo();
                setCart(data?.data); 
            } catch (error) {
                console.error(error);
            }
        };

        useEffect(() => {
            fetchCartWithPagination();
            getLoginInfo();
        }, []);
	return (
		<div>
			{/* <!-- Breadcrumbs --> */}
			<div className="breadcrumbs">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="bread-inner">
								<ul className="bread-list">
									<li><a href="">Home<i className="fas fa-arrow-right"></i></a></li>
									<li className="active"><a href="javascript:void(0);">Checkout</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- End Breadcrumbs --> */}
			{/* <!-- Start Contact --> */}
			<section id="contact-us" className="contact-us section">
				<div className="container-fluid">
					<div className="contact-head">
						<div className="row">
							<div className="col-lg-7 col-12">
								<div className="form-main">
									<div className="title">
										<h4>Checkout</h4>
									</div>
									<form className="form-contact form contact_form">
										<div className="row">
											<div className="col-lg-6 col-12">
												<div className="form-group">
													<label>Your Name<span>*</span></label>
													<input name="name" id="name" type="text" value={userInfo?.data?.name} placeholder="Enter Your Name" onChange={e => handleChange(e, 'name')}/>
												</div>
											</div>
											<div className="col-lg-6 col-12">
												<div className="form-group">
													<label>Phone<span>*</span></label>
													<input id="phone" name="phone" type="number" value={userInfo?.data?.phone} placeholder="Enter Your Phone" onChange={e => handleChange(e, 'phone')} />
												</div>
											</div>
											<div className="col-lg-6 col-12">
												<div className="form-group">
													<label>Your Email<span>*</span></label>
													<input name="email" id="email" type="email" value={userInfo?.data?.email} placeholder="Enter Your Email" onChange={e => handleChange(e, 'email')} />
												</div>
											</div>
											<div className="col-lg-6 col-12">
												<div className="form-group">
													<label>Pincode<span>*</span></label>
													<input id="pincode" name="pincode" type="number" placeholder="Enter Your Pincode" onChange={e => handleChange(e, 'pincode')} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group message">
													<label>Your Address<span>*</span></label>
													<textarea name="address" id="address" cols="30" rows="9" placeholder="Enter Address" onChange={e => handleChange(e, 'address')}></textarea>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group button">
													<button type="button" onClick={createOrder} className="btn">Place Order</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className="col-lg-5 col-12">
								<div className="single-head">
								<table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <td className="text-start">Product Name</td>
                                        <td className="text-end">Total</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    { cart?.products &&
                                        cart?.products.map((item, index) => (
                                            <tr key={index}>
                                        <td className="text-start">{item?.qty}x <a href="https://demo.opencart.com/en-gb/product/macbook">{item?.product?.title}</a>
                                            <br />
                                        </td>
                                        <td className="text-end">{item?.product?.price * item?.qty}</td>
                                    </tr>
                                        ))
                                    }
                                
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="text-end"><strong>Sub-Total</strong></td>
                                        <td className="text-end">{cart?.totalAmount}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td className="text-end"><strong>Total</strong></td>
                                        <td className="text-end">{cart?.totalAmount}</td>
                                    </tr>
                                </tfoot>
                            </table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--/ End Contact --> */}
		</div>


	);
};

export default Checkout;
