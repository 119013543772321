import React, { useState, useEffect } from 'react';
import './../../css/bootstrap.css';
import ProductCarousel from './../../old_pages/productCarousel/productCarousel.js';
import DynamicImageSlider from './../../old_pages/imageSlider/imageSlider.js';
import { getAllBanner } from './../../services/bannerService';
import { getProductWithPagination, getTodaySellingProducts, getTopSellingProducts } from './../../services/productService';
import dividerImg from './../../images/Line.svg';

const Home = () => {
    const [banners, setBanners] = useState([]);
    const [products, setProducts] = useState([]);
    const [topProducts, setTopProducts] = useState([]);
    const [todayProducts, setTodayProducts] = useState([]);
    const loadBanner = async () => {
        try {
        const response = await getAllBanner()
        setBanners((prevItems) => [...response?.data]);
        } catch (error) {
        console.error('Error fetching banners:', error);
        }
    };
    const loadProduct = async () => {
        try {
        const result = await getProductWithPagination()
        setProducts((prevItems) => [...result?.data?.data]);
        } catch (error) {
        console.error('Error fetching banners:', error);
        }
    };

    const loadTopSellingProduct = async () => {
        try {
        const result = await getTopSellingProducts()
        setTopProducts((prevItems) => [...result?.data?.data]);
        } catch (error) {
        console.error('Error fetching banners:', error);
        }
    };

    const loadTodaySellingProduct = async () => {
        try {
        const result = await getTodaySellingProducts()
        setTodayProducts((prevItems) => [...result?.data?.data]);
        } catch (error) {
        console.error('Error fetching banners:', error);
        }
    };

    useEffect(() => {
        loadBanner();
        loadProduct();
        loadTopSellingProduct();
        loadTodaySellingProduct();
    }, []);
    
    return (
        <div>
            <DynamicImageSlider slides={banners} />
            <img src={dividerImg} style={{width:"100%"}}/>
            <ProductCarousel  title="Explore The New Arrivals" description="" products={products} />
            <img src={dividerImg} style={{width:"100%"}}></img>
            <ProductCarousel  title="Top Sellers" description="We Carft exception really fashionable & trendy desings to make you work" products={topProducts} />
            <img src={dividerImg} style={{width:"100%"}}/>
            <DynamicImageSlider slides={banners}/>            <img src={dividerImg} style={{width:"100%"}}/>
            <ProductCarousel  title="Shope by category" description="Explore our unique daily wera jewellery desinges that reffect and Exclusive" products={products} />
                                {/* <!-- End Most Popular Area --> */}
                                <img src={dividerImg} style={{width:"100%"}}/>
                                <ProductCarousel  title="Top Tranding" description="Explore our unique daily wera jewellery desinges that reffect and Exclusive" products={todayProducts} />

                                
                            
                            {/* <!-- Start Shop Services Area --> */}
                            <section className="shop-services section home mt-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            {/* <!-- Start Single Service --> */}
                                            <div className="single-service">
                                                <i className="fas fa-rocket"></i>
                                                <h4>Free shiping</h4>
                                                <p>Orders over $100</p>
                                            </div>
                                            {/* <!-- End Single Service --> */}
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            {/* <!-- Start Single Service --> */}
                                            <div className="single-service">
                                                <i className="fas fa-sync-alt"></i>
                                                <h4>Free Return</h4>
                                                <p>Within 30 days returns</p>
                                            </div>
                                            {/* <!-- End Single Service --> */}
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            {/* <!-- Start Single Service --> */}
                                            <div className="single-service">
                                                <i className="fas fa-lock"></i>
                                                <h4>Sucure Payment</h4>
                                                <p>100% secure payment</p>
                                            </div>
                                            {/* <!-- End Single Service --> */}
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            {/* <!-- Start Single Service --> */}
                                            <div className="single-service">
                                                <i className="fas fa-tag"></i>
                                                <h4>Best Peice</h4>
                                                <p>Guaranteed price</p>
                                            </div>
                                            {/* <!-- End Single Service --> */}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* <!-- End Shop Services Area --> */}
                            {/* <!-- Start Shop Newsletter  --> */}
                            <section className="shop-newsletter section">
                                <div className="container">
                                    <div className="inner-top">
                                        <div className="row">
                                            <div className="col-lg-8 offset-lg-2 col-12">
                                                {/* <!-- Start Newsletter Inner --> */}
                                                <div className="inner">
                                                    <h4>Pick up were you left off</h4>
                                                    {/* <p> Subscribe to our newsletter and get <span>10%</span> off your first purchase</p> */}
                                                    <form action="/subscribe" method="post" className="newsletter-inner">
                                                                      
                                                        <button className="btn" type="submit">Explore</button>
                                                    </form>
                                                </div>
                                                {/* <!-- End Newsletter Inner --> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* <!-- End Shop Newsletter --> */}
                        </div>
                        );
};
export default Home;
