import React from "react"
export default function OrdersHistory (){
    const cart = {
        products:[]
    }
    const tableRows = cart?.products?.length > 0 ? (
        cart?.products.map((item, index) => (
            <tr key={index}>
                <td className="text-center">{item?.product?.title}</td>
                <td className="text-center">{item?.product?.price}</td>
                <td className="text-center">{item?.qty}</td>
                <td className="text-center">{item?.product?.price * item?.qty}</td>
                <td className="text-center"><i className="fas fa-trash-o"></i></td>
            </tr>
        ))
    ) : (
        <tr>
            <td className="text-center" colSpan="6">
                There are no orders available. 
            </td>
        </tr>
    );
    return (
        <>
          <div className="shopping-cart section" style={{backgroundColor: "#ffffff"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* <!-- Shopping Summery --> */}
                            <table className="table shopping-summery">
                                <thead style={{width:"100%"}}>
                                    <tr className="main-hading">
                                        <th style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>ORDER ID</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>TOTAL PRICE</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>ORDER DATE</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}>ACTION</th>
                                        <th className="text-center"  style={{
                                            background: "#eb8928",
                                            color: "#fff",
                                            verticalAlign: "middle"
                                        }}><i className="fas fa-trash-o"></i></th>
                                    </tr>
                                </thead>
                                <tbody id="cart_item_list">
                {tableRows}
            </tbody>
                            </table>
                            {/* <!--/ End Shopping Summery --> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {/* <!-- Total Amount --> */}
                            <div className="total-amount">
                                <div className="row">
                                    <div className="col-lg-8 col-md-5 col-12">
                                    </div>
                                </div>
                            </div>
                            {/* <!--/ End Total Amount --> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}