import axios from 'axios';
import { BASE_URL } from './authService';


export const createContact = async (body) => {
    try {
        const { data } = await axios.post(`${BASE_URL}/contact/create`,{
            ...body
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};