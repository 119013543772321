import React from 'react';
import "./../../css/responsive.css";
import "./../../css/bootstrap.css";
import "./../../css/style.css";

const Faq = () => {
    return (
        <div>
            {/* <!-- Breadcrumbs --> */}
    <div className="breadcrumbs">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="bread-inner">
                        <ul className="bread-list">
                            <li><a href="">Home<i className="fas fa-arrow-right"></i></a></li>
                            <li className="active"><a href="javascript:void(0);">About Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- End Breadcrumbs --> */}
    {/* <!-- About Us --> */}
	<section className="about-us section">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-12">
						<div className="about-content">
														<h3>Welcome To <span>SRH JEWELLERY</span></h3>
							<p>Where Tradition Meets Elegance.

For generations, our family has been crafting exquisite gold and silver jewellry, bringing together timeless artistry and contemporary design. Since our official launch in 2018, we've made it our mission to offer stunning pieces that embody the perfect blend of heritage and modernity.

At SRH JEWELLERY , we believe that jewellry is more than just an accessory; it’s a reflection of your style, your milestones, and your memories. That’s why every piece in our collection is thoughtfully designed and meticulously crafted to make luxury accessible for everyone.

Our journey from a traditional family business to an online destination allows us to reach jewellry lovers far and wide, offering the same personalized experience and quality craftsmanship that our customers have cherished for years. Whether you’re looking for a timeless classic or a bold, contemporary statement piece, you’ll find it here.

We invite you to explore our collection and discover the perfect piece that resonates with your story. At SRH JEWELLERY , we’re not just selling jewellry – we’re sharing our legacy with you.

Indulge in the beauty of fine jewellry and let us be a part of your journey. </p>
						</div>
					</div>
				</div>
			</div>
	</section>
	{/* <!-- End About Us --> */}
            </div>

         
    );
};

export default Faq;
