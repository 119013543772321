import React from "react"
import SideBar from "../../components/sidebar/Sidebar";
import OrdersHistory from "../../components/orders-history/Orders-History";
const UserAccount =  () => {
    return (
        <>
        <div className="container-fluid mt-4 mb-4 orderHistoryAccount">
        <div className="row">
           <div className="col-md-3" style={{borderRight:'1px solid #00000014'}}>
                <SideBar />
           </div>
            <div className="col-md-9">
                <OrdersHistory />
            </div>
            </div>
        </div>
        </>
    )
}
export default UserAccount;