import axios from 'axios';
import { BASE_URL, getAuthInfo } from './authService';

export const getCartInfo = async () => {
    try {
        let authInfo = await getAuthInfo();
        const { data } = await axios.get(`${BASE_URL}/cart/info`, {
            headers:{
                Authorization:authInfo?.token
            }
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const createCart = async (qty, productId) => {
    try {
        let authInfo = await getAuthInfo();
        if(!authInfo) return;
        const { data } = await axios.post(`${BASE_URL}/cart/create`,{
            qty:qty,
            productId:productId
        },{
            headers:{
                Authorization:authInfo?.token
            }
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const getCartById = async (category) => {
    try {
        const { data } = await axios.get(`${BASE_URL}/cart/${category}`);
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};


export const updateCart = async (category) => {
    try {
        const { data } = await axios.put(`${BASE_URL}/cart/${category}`);
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};


export const deleteCart = async (category) => {
    try {
        const { data } = await axios.delete(`${BASE_URL}/cart/${category}`);
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

