import axios from 'axios';
import { BASE_URL, getAuthInfo } from './authService';

export const getOrderWithPagination = async (currentPage, limit = 10,query) => {
    try {
        let authInfo = await getAuthInfo();
        if(!authInfo) return;
        const { data } = await axios.get(`${BASE_URL}/order/get_with_paginate`, {
            params: {
                currentPage: currentPage,
                limit: limit,
                ...query
            },
            headers:{
                    Authorization:authInfo?.token
                }
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

export const placeOrder = async (orderData) => {
    try {
        let authInfo = await getAuthInfo();
        if(!authInfo) return;
        const { data } = await axios.post(`${BASE_URL}/order/create`,{
            ...orderData
        },{
            headers:{
                Authorization:authInfo?.token
            }
        });
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};