import React, { useEffect, useState } from "react";
import "../../css/responsive.css";
import "../../css/bootstrap.css";
import "../../css/style.css";
import SideBar from "../../components/sidebar/Sidebar";
import { getAuthInfo } from "../../services/authService";

const Profile = () => {
        const[userInfo, setUserInfo] = useState({})

       
        const getLoginInfo = async () => {
            try {
                const result = await getAuthInfo();
                if(result?.token) {
					setUserInfo(result);
				}
                } catch (error) {
                    console.error('Error fetching authInfo:', error);
                }
        };
        const handleChange = async (e, field) => {
            setUserInfo({[field]: e.target.value})
        };


        useEffect(() => {
            getLoginInfo();
        }, []);
	return (
		<div className="container-fluid mt-4 mb-4 orderHistoryAccount">
        <div className="row">
           <div className="col-md-3" style={{borderRight:'1px solid #00000014'}}>
                <SideBar selected={'profile'}/>
           </div>
            <div className="col-md-9">
			<section className="contact-us">
		<div className="container-fluid">
			<div className="contact-head">
				<div className="row">
					<div className="col-lg-12 col-12">
						<div className="p-3">
							<form className="form-contact1 form contact_form1">
								<div className="row">
									<div className="col-lg-12 col-12">
										<div className="form-group">
											<label>Your Name<span>*</span></label>
											<input name="name" id="name" type="text" value={userInfo?.data?.name} placeholder="Enter Your Name" onChange={e => handleChange(e, 'name')}/>
										</div>
									</div>
									<div className="col-lg-12 col-12">
										<div className="form-group">
											<label>Phone<span>*</span></label>
											<input id="phone" name="phone" type="number" value={userInfo?.data?.phone} placeholder="Enter Your Phone" onChange={e => handleChange(e, 'phone')} />
										</div>
									</div>
									<div className="col-lg-12 col-12">
										<div className="form-group">
											<label>Your Email<span>*</span></label>
											<input name="email" id="email" type="email" value={userInfo?.data?.email} placeholder="Enter Your Email" onChange={e => handleChange(e, 'email')} />
										</div>
									</div>
									<div className="col-12">
										<div className="form-group button">
											<button type="button" className="btn">Submit</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
            </div>
            </div>
        </div>
		

	);
};

export default Profile;
