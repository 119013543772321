import React, { useState } from 'react';
import "../../css/responsive.css";
import "../../css/bootstrap.css";
import "../../css/style.css";
import { createContact } from '../../services/contactService';

const Contact = () => {
	const [contact, setContact] = useState({})
	const handleContact = async () => {
		try {
			console.log("contact",contact);
			const result = await createContact({
				name: contact.name,
				email: contact.email,
				message: contact.message,
				status: contact.status,
				phone: contact.phone
			});
			console.log("result=====>11", result);
		} catch (error) {
			console.log('error', error);
		}
	}

	const handleChange = async (e, field) => {
		setContact({ ...contact, [field]: e.target.value })
	};
	return (
		<div>
			{/* <!-- Breadcrumbs --> */}
			<div className="breadcrumbs">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="bread-inner">
								<ul className="bread-list">
									<li><a href="">Home<i className="fas fa-arrow-right"></i></a></li>
									<li className="active"><a href="javascript:void(0);">Contact</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- End Breadcrumbs --> */}
			{/* <!-- Start Contact --> */}
			<section id="contact-us" className="contact-us section">
				<div className="container">
					<div className="contact-head">
						<div className="row">
							<div className="col-lg-8 col-12">
								<div className="form-main">
									<div className="title">
										<h4>Get in touch</h4>
									</div>
									<form className="form-contact form contact_form">
										<div className="row">
											<div className="col-lg-12 col-12">
												<div className="form-group">
													<label>Your Name<span>*</span></label>
													<input name="name" id="name" type="text" placeholder="Enter Your Name" onChange={e => handleChange(e, 'name')} />
												</div>
											</div>
											<div className="col-lg-6 col-12">
												<div className="form-group">
													<label>Your Email<span>*</span></label>
													<input name="email" type="email" id="email" placeholder="Enter Email Address" onChange={e => handleChange(e, 'email')} />
												</div>
											</div>
											<div className="col-lg-6 col-12">
												<div className="form-group">
													<label>Your Phone<span>*</span></label>
													<input id="phone" name="phone" type="number" placeholder="Enter Your Phone" onChange={e => handleChange(e, 'phone')} />
												</div>
											</div>
											<div className="col-12">
												<div className="form-group message">
													<label>your Message<span>*</span></label>
													<textarea name="message" id="message" cols="30" rows="9" placeholder="Enter Message" onChange={e => handleChange(e, 'message')}></textarea>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group button">
													<button type="button" onClick={handleContact} className="btn ">Send Message</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div className="col-lg-4 col-12">
								<div className="single-head">
									<div className="single-info">
										<i className="fa fa-phone"></i>
										<h4 className="title">Call us Now:</h4>
										<ul>
											<li> +91-7014302427 </li>
										</ul>
									</div>
									<div className="single-info">
										<i className="fa fa-envelope-open"></i>
										<h4 className="title">Email:</h4>
										<ul>
											<li><a href="mailto:info@yourwebsite.com"> srhjewellerybwr@gmail.com</a></li>
										</ul>
									</div>
									<div className="single-info">
										<i className="fa fa-location-arrow"></i>
										<h4 className="title">Our Address:</h4>
										<ul>
											<li> 146, Vinodilal Gali, Teja Chowk, Near Paanch Batti, Beawar. (Raj) </li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--/ End Contact --> */}

			{/* <!-- Map Section --> */}
			<div className="map-section">
				<div id="myMap">
					<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14130.857353934944!2d85.36529494999999!3d27.6952226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sne!2snp!4v1595323330171!5m2!1sne!2snp" width="100%" height="100%" frameborder="0" style={{ border: "0" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
				</div>
			</div>
			{/* <!--/ End Map Section --> */}

		</div>


	);
};

export default Contact;
