import React from "react";
import { Link } from "react-router-dom";
import './Sidebar.css';

export default function SideBar({selected}) {
    return (
        <>
        <ul className="list-group list-group-flush mt-2">
                    <Link to="/profile" className="list-group-item" style={{backgroundColor: selected === "profile" ? "#add8e6" : ""}}> ACCOUNT </Link>
                    <Link to="/orders-history" className="list-group-item" style={{backgroundColor: selected === "order-history" ? "#add8e6" : ""}}>ORDERS HISTORY</Link>
                    <Link to="/update-password" className="list-group-item" style={{backgroundColor: selected === "update-password" ? "#add8e6" : ""}}>UPDATE PASSWORD</Link>
                    {/* <Link to="/orders" className="list-group-item">NEW ORDERS</Link>
                    <Link to="/mywishlist" className="list-group-item">MY WISHLIST</Link>
                    <Link to="/transaction" className="list-group-item">TRANSACTIONS</Link>
                    <Link to="/profile-setting" className="list-group-item"> PROFILE SETTING</Link> */}
                    {/* <Link to="/logout" className="list-group-item">LOG OUT</Link> */}
                </ul>
            
        </>
    )
}
