import "../../css/responsive.css";
import "../../css/bootstrap.css";
import "../../css/style.css";
import React, { useState,useEffect } from 'react';
import ProductModal from '../../components/product-model/ProductModel.js';
import { getProductWithPagination } from './../../services/productService';
import {getCategoryWithChildren} from './../../services/categoryService';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ImageComponent from "../../components/Image/Image.js";

const ProductGrid = () => {
    const { productName: slug } = useParams();
    const [productList, setProducts] = useState([]);
    const [activeDiv, setActiveDiv] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [categories, setCategories] = useState([]);
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category');
    const title = searchParams.get('title');
    const parent = searchParams.get('parent');
    const handleClick = (divNumber) => {
        setActiveDiv(divNumber);
    };

    const loadProduct = async () => {
        try {
            let query = {};
            if(category) query['category'] = category;
            if(title) query['title'] = title;
        const result = await getProductWithPagination(1, 100, query)
        setProducts((prevItems) => [...result?.data?.data]);
        } catch (error) {
        console.error('Error fetching banners:', error);
        }
    };

    const loadCategories = async () => {
        try {
          const response = await getCategoryWithChildren(1,100, )
          const { data } = response;
          console.log("data==============> category", data)
          setCategories((prevItems) => [...data]);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };
    
    const handleShowModal = (content) => {
        setShowModal(true);
        setModalContent(content);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalContent(null);
    };

    useEffect(() => {
        loadProduct();
        loadCategories();
    }, []);

    return (
        <div>
            {/* <!-- Breadcrumbs --> */}
            <div className="breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="bread-inner">
                                <ul className="bread-list">
                                    <li><a href="index1.html">Home<i className="ti-arrow-right"></i></a></li>
                                    <li className="active"><a href="blog-single.html">Shop Grid</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Breadcrumbs --> */}

            {/* <!-- Product Style --> */}
            <form action="/filter" method="POST">
                <input type="hidden" name="_token" value="Sin5WiVKfgn3E386VRkz7p5IONQ33i62W00avwEm" />
                <section className="product-area shop-sidebar shop section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 col-12">
                                <div className="shop-sidebar">
                                    {/* <!-- start category --> */}
                                    <div className="single-widget category">
                                        <h3 className="title">Categories</h3>
                                        <ul className="categor-list">
                                            {categories.map((category, index) => (
                                                <li key={index}>
                                                    <a href={category?.slug}>{category?.title}</a>
                                                    {category.children.length > 0 && (
                                                        <ul>
                                                            {category.children.map((subcat, subIndex) => (
                                                                <li key={subIndex}>
                                                                    <a href={subcat?.slug}>{subcat?.title}</a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9 col-md-8 col-12">
                                <div className="row">
                                    <div className="col-12">
                                        {/* <!-- Shop Top----show and sort by --> */}
                                        <div className="shop-top">
                                            <div className="shop-shorter">
                                                <div className="single-shorter">
                                                    <label>Sort By :</label>
                                                    <select className='sortBy' name='sortBy' onchange="this.form.submit();">
                                                        <option value="">Default</option>
                                                        <option value="title">Name</option>
                                                        <option value="price">Price</option>
                                                        <option value="category">Category</option>
                                                        <option value="brand">Brand</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <ul className="view-mode">
                                                <li className={activeDiv == 1 ? "active" : ""} onClick={() => handleClick(1)}><a  ><i
                                                    className="fa fa-th-large" ></i></a></li>
                                                <li className={activeDiv == 2 ? "active" : ""} onClick={() => handleClick(2)}><a  ><i
                                                    className="fa fa-th-list"></i></a></li>
                                            </ul>
                                        </div>
                                        {/* <!--/ End Shop Top --> */}
                                    </div>
                                </div>
                                {/* for List view or normal view products start */}
                                {activeDiv === 1 && (
                                    <div className="row">
                                        {productList.map((product, index) => (
                                            <div className="col-lg-4 col-md-6 col-12" key={index}>
                                                <div className="single-product">
                                                    <div className="product-img">
                                                        <a href={`/product-detail/${product?.slug}`}>
                                                            <ImageComponent className="default-img" src={product?.image} alt={product?.title} />
                                                            <ImageComponent className="hover-img" src={product?.image} alt={product?.title} />
                                                            <span className="price-dec">{product?.discountPrice}</span>
                                                        </a>
                                                        <div className="button-head">
                                                            <div className="product-action">
                                                                <i className="fas fa-eye" title="Quick Shop"  onClick={() => handleShowModal(product)} style={{ marginRight: "10px" }}></i>
                                                                <a title="Wishlist" href={product.wishlistUrl}>
                                                                    <i className="fas fa-heart" title="Add to Wishlist" style={{ paddingRight: "10px" }}></i>
                                                                </a>
                                                            </div>
                                                            <div className="product-action-2">
                                                                <a title="Add to cart" href={product.cartUrl}>Add to cart</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-content">
                                                        <h3><a href={`/product-detail/${product?.slug}`}>{product?.title}</a></h3>
                                                        <span>{product?.discountPrice}</span>
                                                        <del style={{ paddingLeft: "4%" }}>{product?.price}</del>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {activeDiv === 2 && (
                                    <div className='shop-list'>
                                        <div className="row">
                                            {productList.map((product, index) => (
                                                <div className="col-12" key={index}>
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-6 col-sm-6">
                                                            <div className="single-product">
                                                                <div className="product-img">
                                                                    <a href={product?.slug}>
                                                                        <ImageComponent className="default-img" src={product?.image} alt={product?.title} />
                                                                        <ImageComponent className="hover-img" src={product?.image} alt={product?.title} />
                                                                        {/* <img className="default-img" src={product?.image} alt={product?.title} />
                                                                        <img className="hover-img" src={product?.image} alt={product?.title} /> */}
                                                                    </a>
                                                                    <div className="button-head">
                                                                        <div className="product-action">
                                                                            <i className="fas fa-eye" title="Quick Shop"  onClick={() => handleShowModal(product)} style={{ marginRight: "10px" }}></i>
                                                                            <a title="Wishlist" href={`/wishlist/${product?.slug}`}>
                                                                                <i className="fas fa-heart" title="Add to Wishlist" style={{ paddingRight: "10px" }}></i>
                                                                            </a>
                                                                        </div>
                                                                        <div className="product-action-2">
                                                                            <a title="Add to cart" href={product?.addToCartLink}>Add to cart</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 col-md-6 col-12">
                                                            <div className="list-content">
                                                                <div className="product-content">
                                                                    <div className="product-price">
                                                                        <span>{product.price}</span>
                                                                        <del>{product.originalPrice}</del>
                                                                    </div>
                                                                    <h3 className="title">
                                                                        <a href={product.detailLink}>{product.name}</a>
                                                                    </h3>
                                                                </div>
                                                                <p className="des pt-2">
                                                                    <span style={{ color: "rgb(15, 17, 17)", fontFamily: "'Amazon Ember', Arial, sans-serif", fontSize: "14px" }}>
                                                                        {product.description}
                                                                    </span>
                                                                </p>
                                                                <a href="javascript:void(0)" className="btn cart" data-id={product.productId}>Buy Now!</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {/* for List view or normal view products end */}
                            </div>

                        </div>
                    </div>
                </section>
            </form>
            <ProductModal showModal={showModal} handleCloseModal={handleCloseModal} modalContent={modalContent} />
       
        </div>


    );
};

export default ProductGrid;
