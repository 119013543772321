import React,{ useState } from 'react';
import "../../css/responsive.css";
import "../../css/bootstrap.css";
import "../../css/style.css";
import { registerUser } from '../../services/authService';
import { useNavigate } from "react-router-dom";

const Register = () => {
    const navigate = useNavigate();
    const [registerObj, setRegisterObj] = useState({
        email: "",
        password: "",
        name: "",
        phone: ""
      });
      const [error, setError] = useState('');
    
      const handleRegister = async (e) => {
        e.preventDefault();
        try {
          const response = await registerUser(registerObj);
            navigate('/');
        } catch (err) {
          setError('Login failed. Please check your credentials.');
        }
      };
  
    const handleChange = async (e, field) => {
        setRegisterObj({...registerObj, [field]: e.target.value})
    };

    return (
        <div>
            {/* <!-- Breadcrumbs --> */}
    <div className="breadcrumbs">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="bread-inner">
                        <ul className="bread-list">
                            <li><a href="">Home<i className="fas fa-arrow-right"></i></a></li>
                            <li className="active"><a href="javascript:void(0);">Register</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- End Breadcrumbs --> */}
    {/* <!-- Shop Login --> */}
    <section class="shop login section">
        <div class="container">
            <div class="row"> 
                <div class="col-lg-6 offset-lg-3 col-12">
                    <div class="login-form">
                        <h2>Register</h2>
                        {/* <!-- Form --> */}
                        <form class="form" method="post" onSubmit={handleRegister}>
                                                     <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Your Name<span>*</span></label>
                                        <input type="text" placeholder="" required="required" onChange={e => handleChange(e, 'name')}/>
                                                                            </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Your Email<span>*</span></label>
                                        <input type="text" placeholder="" required="required" onChange={e => handleChange(e, 'email')}/>
                                                                            </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Your Password<span>*</span></label>
                                        <input type="password" placeholder="" required="required" onChange={e => handleChange(e, 'password')}/>
                                                                            </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Phone<span>*</span></label>
                                        <input type="text" placeholder="" required="required" onChange={e => handleChange(e, 'phone')}/>
                                                                            </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group login-btn">
                                        <button class="btn" type="submit">Register</button>
                                        <a href="/login" class="btn btn-google">Back to Login</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {/* <!--/ End Form --> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--/ End Login --> */}
            </div>

         
    );
};

export default Register;
