import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home.js';
import ProductDetail from './pages/product-detail/Product-Detail.js';
import Cart from './pages/cart/Cart.js';
import MainLayout from './layout/mainLayout';
import Login from './pages/login/Login.js'; 
import Register from './pages/register/Register.js';
import Contact from './pages/contact/Contact.js';
import About from './pages/about/About.js';
import Wishlist from './pages/wishlist/Wishlist.js';
import Products from './pages/products/Products.js';
import Checkout from './pages/checkout/Checkout.js';
import Profile from './pages/profile/Profile.js';
import "./css/responsive.css";
import "./css/style.css";
import "./css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { getProfileInfo } from './services/authService.js';
import TermsCondition from './pages/terms-condition/Terms-Condition.js';
import Faq from './pages/faq/Faq.js';
import PrivacyPolicy from './pages/privacy-policy/Privacy-Policy.js';
// import OrderPlaced from './pages/orderPlaced/orderPlaced.js';
import UserAccount from './pages/user-account/UserAccount.js';

const App = () => {
  const getProfileUpdate = async () => {
    await getProfileInfo();
  }

  useEffect(() => {
    getProfileUpdate();
  });
  return (
    <Router>
      <MainLayout>
      
      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product-detail/:productName" element={<ProductDetail />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user-account" element={<UserAccount />} />
          <Route path="login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<About />} />
          <Route path="/product-grids" element={<Products />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/checkout" element={<Checkout />} />
          {/* <Route path="/order-placed" element={<OrderPlaced />} /> */}


          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      
    
        
      </MainLayout>
    </Router>
  );
};

export default App;
